import Link from "next/link";
import ScrollToTop from "react-scroll-to-top";
import useTranslation from "next-translate/useTranslation";
import Image from 'next/image';
import getConfig from 'next/config';


/**
 * Flatten .popular or .native
 */
function flattenCoinData(coinObj) {
    const allPairs = [];
    if (!coinObj) return [];

    const coinsArray = Object.values(coinObj);
    const remove0x = (token) =>
        token.includes("-0x") ? token.split("-")[0] : token;

    coinsArray.forEach((coin) => {
        if (!coin.identifier) return;
        const [parentNetwork, parentTokenRaw] = coin.identifier.split(".");
        if (!parentNetwork || !parentTokenRaw) return;

        const parentShortName =
            coin.giveCoinShortname || remove0x(parentTokenRaw);

        (coin.permition || []).forEach((perm) => {
            if (!perm.identifier) return;
            const [childNetwork, childTokenRaw] = perm.identifier.split(".");
            if (!childNetwork || !childTokenRaw) return;

            const childShortName =
                perm.getCoinShortName || remove0x(childTokenRaw);

            // e.g. "BTC-BTC-ETH-ETH"
            const slug = `${parentNetwork}-${parentTokenRaw}-${childNetwork}-${childTokenRaw}`;
            const displayText = `${parentShortName} to ${childShortName}`;

            allPairs.push({ slug, displayText });
        });
    });

    return allPairs;
}

/**
 * Flatten .buy so that for each parent coin,
 * we iterate over its permition array, producing:
 * "Buy {childCoinName} ({childShortName})"
 * with the same "network1-token1-network2-token2" link format.
 */
function flattenBuyData(buyObj) {
    const allPairs = [];
    if (!buyObj) return [];

    const remove0x = (token) =>
        token.includes("-0x") ? token.split("-")[0] : token;

    const coinsArray = Object.values(buyObj);

    coinsArray.forEach((coin) => {
        if (!coin.identifier) return;
        const [parentNet, parentTokenRaw] = coin.identifier.split(".");
        if (!parentNet || !parentTokenRaw) return;

        // For each child in the parent’s permition array
        (coin.permition || []).forEach((perm) => {
            if (!perm.identifier) return;
            const [childNet, childTokenRaw] = perm.identifier.split(".");
            if (!childNet || !childTokenRaw) return;

            // e.g., "SHIB", "SHIB"
            const childShortName =
                perm.getCoinShortName || remove0x(childTokenRaw);
            // e.g. "Shiba Inu"
            let childName = perm.getCoinName || childShortName;

            if (childName === "Maya Protocol") {
                childName = "Maya";
            }

            // Display => "Buy Shiba Inu (SHIB)"
            const displayText = `Buy ${childName} (${childShortName})`;

            // Slug => same 4-part format
            const slug = `${parentNet}-${parentTokenRaw}-${childNet}-${childTokenRaw}`;

            allPairs.push({ slug, displayText });
        });
    });

    return allPairs;
}

export default function Footer({ pairsData = {}, categories = [] }) {
    const { t } = useTranslation("common");

    // Flatten coin data
    const popularPairs = flattenCoinData(pairsData.popular);
    const nativePairs = flattenCoinData(pairsData.native);
    const buyPairs = flattenBuyData(pairsData.buy);
    const { publicRuntimeConfig } = getConfig();
    const basePath = publicRuntimeConfig.basePath || '';

    return (
        <>
            <footer className="footer brand-1">
                <div
                    style={{
                        backgroundImage: `url(${basePath}/images/others/bglandscape-stars-dark-small-square-low.webp)`,
                        backgroundSize: "auto",
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        opacity: 0.1,
                        opacity: 0.1,
                    }}
                ></div>
                <div className="container">
                    <div className="footer__wrapper">
                        <div className="footer__top footer__top--style1">
                            <div className="row gy-5 gx-4">
                                {/* Left Column: Logo & About */}
                                <div className="col-md-12">
                                    <div className="footer__about">
                                        <Link href="/" className="footer__about-logo">
                                            <Image
                                                className="dark"
                                                src="/images/logo/instaswapFullLogo.png"
                                                alt="logo"
                                                width={300}
                                                height={50}
                                                style={{ margin: "0px" }}
                                            />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="footer__about">
                                        <p className="footer__about-moto">
                                            {t("footer.aboutMoto", { cross: "Cross", chain: "Chain" })}
                                        </p>
                                    </div>
                                </div>

                                {/* Quick Links */}
                                <div className="col-md-2 col-sm-4 col-6">
                                    <div className="footer__links">
                                        <div className="footer__links-tittle">
                                            <h3 style={{ fontSize: "1.2rem" }}>{t("footer.about")}</h3>
                                        </div>
                                        <div className="footer__links-content">
                                            <ul className="footer__linklist">
                                                <li className="footer__linklist-item">
                                                    <Link href="/about">{t("footer.roadmap")}</Link>
                                                </li>
                                                <li className="footer__linklist-item">
                                                    <Link href="/howswapswork">{t("footer.howItWorks")}</Link>
                                                </li>
                                                <li className="footer__linklist-item">
                                                    <Link href="/walletless">{t("footer.walletlessSwaps")}</Link>
                                                </li>
                                                <li className="footer__linklist-item">
                                                    <Link href="/affiliate">{t("footer.affiliateProgram")}</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* Support */}
                                <div className="col-md-2 col-sm-4 col-6">
                                    <div className="footer__links">
                                        <div className="footer__links-tittle">
                                            <h3 style={{ fontSize: "1.2rem" }}>{t("footer.business")}</h3>
                                        </div>
                                        <div className="footer__links-content">
                                            <ul className="footer__linklist">
                                                <li className="footer__linklist-item">
                                                    <Link href="/for-business">{t("footer.becomePartner")}</Link>
                                                </li>
                                                <li className="footer__linklist-item">
                                                    <Link href="/api-integration">{t("footer.apiIntegration")}</Link>
                                                </li>
                                                <li className="footer__linklist-item">
                                                    <Link href="/swap-widget">{t("footer.widgetIntegration")}</Link>
                                                </li>
                                                <li className="footer__linklist-item">
                                                    <Link href="/partners-portal">{t("footer.partnersCMS")}</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* News Section */}
                                <div className="col-md-2 col-sm-4">
                                    <div className="footer__links">
                                        <div className="footer__links-tittle">
                                            <h3 style={{ fontSize: "1.2rem" }}>{t("menu.news")}</h3>
                                        </div>
                                        <div className="footer__links-content">
                                            <ul className="footer__linklist">
                                                <li className="footer__linklist-item">
                                                    <Link href="https://blog.instaswap.com/" target="_blank">
                                                        {t("footer.latestNews")}
                                                    </Link>
                                                </li>
                                                {categories.map((category) => (
                                                    <li className="footer__linklist-item" key={category.id}>
                                                        <Link
                                                            href={`https://blog.instaswap.com/category/${category.slug}/`}
                                                            target="_blank"
                                                        >
                                                            {category.name}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* Announcements Section */}
                                <div className="col-md-2 col-sm-4">
                                    <div className="footer__links">
                                        <div className="footer__links-tittle">
                                            <h3 style={{ fontSize: "1.2rem" }}>{t("menu.announcements")}</h3>
                                        </div>
                                        <div className="footer__links-content">
                                            <ul className="footer__linklist">
                                                <li className="footer__linklist-item">
                                                    <Link
                                                        href="https://blog.instaswap.com/the-fundamentals-of-decentralized-finance-defi-a-comprehensive-guide/"
                                                        target="_blank"
                                                    >
                                                        {t("footer.getStartedWithDeFi")}
                                                    </Link>
                                                </li>
                                                <li className="footer__linklist-item">
                                                    <Link
                                                        href="https://blog.instaswap.com/easy-guide-to-native-crypto-cross-chain-swaps-via-the-instaswap-app/"
                                                        target="_blank"
                                                    >
                                                        {t("footer.swapGuide")}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div
                                    className="footer__app"
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        width: "100%",
                                        flexWrap: "nowrap",
                                        alignContent: "center",
                                    }}
                                >
                                    <div className="footer__app-item footer__app-item--apple" style={{ marginRight: "20px" }}>
                                        <div className="footer__app-inner">
                                            <div className="footer__app-thumb">
                                                <Link href="mailto:support@instaswap.com" className="stretched-link" aria-label="Contact support via email">
                                                    <Image src="/images/icon/mail-dark.svg" alt="Mail icon" width={32} height={32} loading="lazy" style={{ fill: "#000" }} />
                                                </Link>
                                            </div>
                                            <div className="footer__app-content">
                                                <span>Constact us on</span>
                                                <p className="mb-0">support@instaswap.com</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="footer__app-item footer__app-item--playstore2">
                                        <div className="footer__app-inner">
                                            <div className="footer__app-thumb">
                                                <Link href="https://discord.gg/um6NazJSMk" target="_blank" className="stretched-link" aria-label="Join our Discord Community">
                                                    <Image src="/images/icon/discord-dark.svg" alt="Discord icon" width={32} height={32} loading="lazy" />
                                                </Link>
                                            </div>
                                            <div className="footer__app-content">
                                                <span>Find us on</span>
                                                <p className="mb-0">Discord Community</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Bottom area with social links, etc. */}
                        <div className="footer__bottom">
                            {/* Popular Pairs */}
                            <div className="footer__exchange-pairs mt-30 mb-60 container-fluid">
                                <h3 className="text-white mb-40">{t("footer.popularPairs")}</h3>
                                {/* Group pairs into chunks of 3 */}
                                {Array.from({ length: Math.ceil(popularPairs.length / 5) }, (_, rowIndex) => (
                                    <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 g-4 mb-3" key={rowIndex}>
                                        {popularPairs.slice(rowIndex * 5, (rowIndex + 1) * 5).map((pair, index) => (
                                            <div className="col" key={index}>
                                                <Link href={`/pairs/${pair.slug}`}>
                                                    {pair.displayText.replace("to", t("footer.to"))}
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>

                            {/* Native Pairs */}
                            <div className="footer__exchange-pairs mt-30 mb-60 container-fluid">
                                <h3 className="text-white mb-40">{t("footer.nativePairs")}</h3>
                                {Array.from({ length: Math.ceil(nativePairs.length / 5) }, (_, rowIndex) => (
                                    <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 g-4 mb-3" key={rowIndex}>
                                        {nativePairs.slice(rowIndex * 5, (rowIndex + 1) * 5).map((pair, index) => (
                                            <div className="col" key={index}>
                                                <Link href={`/pairs/${pair.slug}`}>
                                                    {pair.displayText.replace("to", t("footer.to"))}
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>

                            {/* Buy */}
                            <div className="footer__exchange-pairs mt-30 mb-60 container-fluid">
                                <h3 className="text-white mb-40">{t("footer.buy")}</h3>
                                {Array.from({ length: Math.ceil(buyPairs.length / 5) }, (_, rowIndex) => (
                                    <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 g-4 mb-3" key={rowIndex}>
                                        {buyPairs.slice(rowIndex * 5, (rowIndex + 1) * 5).map((pair, index) => (
                                            <div className="col" key={index}>
                                                <Link href={`/pairs/${pair.slug}`}>
                                                    {pair.displayText.replace("Buy", t("footer.buy"))}
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>

                            <div className="footer__end">
                                <div className="footer__end-copyright">
                                    <p className="mb-0">© 2025 All Rights Reserved |</p>
                                    <div className="footer__app-item footer__linklist-item" style={{ marginLeft: "5px" }}>
                                        <Link href="/termsAndConditions">{t("footer.terms")}</Link>
                                    </div>
                                </div>
                                <div>
                                    <ul className="social">
                                        <li className="social__item">
                                            <Link href="https://discord.gg/um6NazJSMk" className="social__link social__link--style22" target="_blank" aria-label="Follow us on Discord">
                                                <Image src="/images/icon/discord.svg" alt="Discord icon" width={20} height={20} loading="lazy" />
                                            </Link>
                                        </li>
                                        <li className="social__item">
                                            <Link href="https://t.me/+TaTcoxpScgBbl4JJ" className="social__link social__link--style22" target="_blank" aria-label="Follow us on Telegram">
                                                <Image src="/images/icon/telegram.svg" alt="Telegram icon" width={20} height={20} loading="lazy" />
                                            </Link>
                                        </li>
                                        <li className="social__item">
                                            <Link href="https://instaswap.medium.com/" className="social__link social__link--style22" target="_blank" aria-label="Follow us on Medium">
                                                <Image src="/images/icon/medium.svg" alt="Medium icon" width={20} height={20} loading="lazy" />
                                            </Link>
                                        </li>
                                        <li className="social__item">
                                            <Link href="https://www.youtube.com/@instaswapdex" className="social__link social__link--style22" target="_blank" aria-label="Follow us on YouTube">
                                                <Image src="/images/icon/youtube.svg" alt="YouTube icon" width={20} height={20} loading="lazy" />
                                            </Link>
                                        </li>
                                        <li className="social__item">
                                            <Link href="https://x.com/instaswap_io" className="social__link social__link--style22" target="_blank" aria-label="Follow us on Twitter">
                                                <Image src="/images/icon/X.svg" alt="X icon" width={20} height={20} loading="lazy" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer Shapes */}
                <div className="footer__shape">
                    <div className="bckImg bckImgLeft">
                        <picture>
                            <source media="(min-width: 768px)" srcSet="/images/hero/midground-large.webp" />
                            <Image
                                width={1600}
                                height={400}
                                src="/images/hero/midground-small.webp"
                                alt="Left mountain"
                                loading="lazy"
                            />
                        </picture>
                    </div>
                    <div className="bckImg bckImgRight">
                        <picture>
                            <source media="(min-width: 768px)" srcSet="/images/hero/midground-large.webp" />
                            <Image
                                width={1800}
                                height={400}
                                src="/images/hero/midground-small.webp"
                                alt="right mountain"
                                loading="lazy"
                            />
                        </picture>
                    </div>
                    <span className="footer__shape-item footer__shape-item--1">

                        <img
                            src="/images/anim-icons/gifs/convert-bitcoin-to-ethereum.gif"
                            alt="convert-bitcoin-to-ethereum"
                            width={70}
                            height={70}
                            style={{ display: 'block' }}
                        />

                    </span>
                </div>
            </footer>

            <ScrollToTop
                smooth
                height="18px"
                width="18px"
                className="scrollToTop scrollToTop--home1"
                viewBox="0 0 18 18"
                svgPath="M4 8l5 -5l5 5l-1 1l-4 -4l-4 4ZM4 12l5 -5l5 5l-1 1l-4 -4l-4 4Z"
                style={{ fill: "#000" }}
            />
        </>
    );
}
